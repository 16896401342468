<template>
    <div>
        <admin v-if="user && user.role === 'admin'"></admin>
        <user v-else-if="user && user.role === 'user'"></user>
        <div v-else>Kullanıcı girişi yapınız.</div>
    </div>
</template>

<script>
    import admin from '@/components/admin.vue';
    import user from '@/components/user.vue';

    export default {
        components: {
            admin,
            user
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        }
    };
</script>
