import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

Vue.use(Toast);

axios.defaults.baseURL = 'https://api.plusiyer.com.tr/api';

axios.interceptors.request.use(function(config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

// Response interceptor ekle
axios.interceptors.response.use(
    response => {
        // Başarılı yanıtlar
        return response;
    },
    error => {
        if (error.response) {
            // API tarafından hata yanıtı gönderildiğinde
            if (error.response.status === 401 || error.response.status === 403) {
                // Oturum sonlandırma ve login sayfasına yönlendirme
                store.dispatch('logout');
                router.push('/auth/login');
            }
        } else if (error.request) {
            // İstek gönderildi ancak yanıt alınamadı (örneğin, sunucuya bağlantı kurulamadı)
            Vue.prototype.$toast.error(`Sunucuya bağlantı kurulamadı: ${error.message}`);
            store.dispatch('logout');
            router.push('/auth/login');
            // Uygun bir hata mesajı gösterilebilir veya başka bir işlem yapılabilir
        } else {
            // İstek ayarlarında bir hata oluştu
            Vue.prototype.$toast.error(`İstek hatası: ${error.message}`);
            store.dispatch('logout');
            router.push('/auth/login');
        }
        return Promise.reject(error);
    }
);

//bootstrap vue
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

//perfect scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar';
Vue.use(PerfectScrollbar);

//vue-scrollactive
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

//vue-meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

//Sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
    confirmButtonColor: '#4361ee',
    cancelButtonColor: '#e7515a'
};
Vue.use(VueSweetalert2, options);

//portal vue
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

//vue-i18n
import i18n from './i18n';

Vue.config.productionTip = false;

// set default settings
import appSetting from './app-setting';
Vue.prototype.$appSetting = appSetting;
appSetting.init();

new Vue({
    router,
    store,
    i18n,
    created() {
        const token = localStorage.getItem('token');
        if (token) {
            store.commit('setToken', token);
            // Opsiyonel: Kullanıcı bilgilerini de localStorage'dan alıp store'a commit edebilirsiniz
            const user = localStorage.getItem('user');
            if (user) {
                store.commit('setUser', JSON.parse(user));
            }
        }
    },
    render: h => h(App)
}).$mount('#app');
