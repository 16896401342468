<template>
    <div class="user-page" v-if="isWithinSystemHours">
        <!-- Arama barı -->
        <div class="search-bar">
            <input type="text" class="form-control" placeholder="Kategori veya ürün ara..." v-model="searchQuery" />
        </div>

        <!-- Sütun başlıklarını bir kez göster -->
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Ürün</th>
                        <th>{{ user.email.split('@')[0] }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="category in filteredCategories">
                        <tr class="category-header" :key="category.categoryid">
                            <td colspan="2">
                                <h3>{{ category.categoryName }}</h3>
                            </td>
                        </tr>
                        <tr v-for="product in category.products" :key="product.productid">
                            <td>{{ product.productName }}</td>
                            <td>
                                <input type="number" class="form-control" :value="getUserRequestAmount(product.productid)" @change="updateUserRequest($event.target.value, product.productid)" />
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tfoot>
                    <tr class="total-row">
                        <td><strong>Toplam</strong></td>
                        <td>{{ totalAmountForCurrentUser }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div v-else class="mt-4">
        <div class="card border-primary">
            <div class="card-header bg-primary text-white">
                Sistem Çalışma Saatleri Dışında
            </div>
            <div class="card-body">
                <p class="card-text">
                    Lütfen aşağıdaki saatler arasında tekrar deneyin:
                </p>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><strong>Açılış Saati:</strong> {{ systemHours.openingTime }}</li>
                    <li class="list-group-item"><strong>Kapanış Saati:</strong> {{ systemHours.closingTime }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                categories: [], // Kategorize edilmiş ürünlerin listesi
                userRequests: [], // Kullanıcı taleplerini tutacak dizi
                userId: null, // Aktif kullanıcının ID'si
                searchQuery: '',
                systemHours: {
                    openingTime: '',
                    closingTime: ''
                },
                isWithinSystemHours: false
            };
        },
        methods: {
            getUserRequestAmount(productid) {
                const request = this.userRequests.find(req => req.userid === this.userId && req.productid === productid);
                return request ? request.amount : 0;
            },
            updateUserRequest(newAmount, productid) {
                const existingRequest = this.userRequests.find(req => req.userid === this.userId && req.productid === productid);

                if (existingRequest) {
                    // Mevcut bir talep varsa, güncelle (PUT)
                    axios
                        .put(`/userRequest/${this.userId}/${productid}`, { amount: newAmount })
                        .then(() => {
                            this.$toast.success('Kullanıcı talebi başarıyla güncellendi.');
                            // Gerekirse yerel verileri güncelle
                            this.refreshLocalUserRequestData(this.userId, productid, newAmount);
                        })
                        .catch(err => {
                            console.log('request reject', err);
                            this.$toast.error('Kullanıcı talebi güncellendi.');
                        });
                } else {
                    // Talep yoksa, yeni bir talep oluştur (POST)
                    axios
                        .post(`/userRequest`, { userid: this.userId, productid: productid, amount: newAmount })
                        .then(response => {
                            this.$toast.success('Yeni kullanıcı talebi başarıyla oluşturuldu.');
                            // Gerekirse yerel verileri güncelle
                            this.refreshLocalUserRequestData(this.userId, productid, newAmount);
                            this.userRequests.push(response.data);
                            // Reaktif güncellemeyi tetiklemek için userRequests dizisini güncelle
                            this.userRequests = [...this.userRequests];
                        })
                        .catch(() => {
                            this.$toast.error('Yeni kullanıcı talebi oluşturulamadı.');
                        });
                }

                // Sonra bu bilgiyi backend'e gönder
                // POST veya PUT işlemi burada yapılabilir, backend'iniz nasıl tasarlandıysa
            },
            transformData(data) {
                const categoriesMap = new Map();
                data.forEach(item => {
                    if (!categoriesMap.has(item.categoryid)) {
                        categoriesMap.set(item.categoryid, {
                            categoryid: item.categoryid,
                            categoryName: item.categoryName,
                            products: []
                        });
                    }
                    categoriesMap.get(item.categoryid).products.push({
                        productid: item.productid,
                        productName: item.productName,
                        stock: item.stock,
                        incoming: item.incoming,
                        fromout: item.fromout,
                        toout: item.toout
                    });
                });
                return Array.from(categoriesMap.values());
            },
            refreshLocalProductData(updatedProductId) {
                axios
                    .get(`/product/${updatedProductId}`)
                    .then(response => {
                        const updatedProduct = response.data;
                        this.categories.forEach(category => {
                            const index = category.products.findIndex(product => product.productid === updatedProductId);
                            if (index !== -1) {
                                this.$set(category.products, index, updatedProduct);
                            }
                        });
                    })
                    .catch(error => {
                        console.error('Yerel ürün verilerini güncellerken hata oluştu:', error);
                    });
            },

            refreshLocalUserRequestData(userid, productid, newAmount) {
                // Yerel userRequests dizisinde ilgili talebi bul ve güncelle
                const requestIndex = this.userRequests.findIndex(req => req.userid === userid && req.productid === productid);
                if (requestIndex !== -1) {
                    this.$set(this.userRequests, requestIndex, { userid, productid, amount: newAmount });
                }
            },

            isCurrentTimeWithinSystemHours() {
                const now = new Date();
                const openingTime = new Date(now.toDateString() + ' ' + this.systemHours.openingTime);
                const closingTime = new Date(now.toDateString() + ' ' + this.systemHours.closingTime);
                return now >= openingTime && now <= closingTime;
            }
        },
        mounted() {
            // Kullanıcı ID'sini al (oturum açmış kullanıcıdan veya Vuex store'dan)
            this.userId = this.user.id;
            console.log('kullanıcı id', this.userId);

            axios
                .get('/category/getCategoriesWithProducts')
                .then(res => {
                    this.categories = this.transformData(res.data);
                })
                .catch(err => {
                    console.error('Hata:', err);
                    // Burada kullanıcıya uygun bir hata mesajı göster
                });

            axios
                .get('/userRequest')
                .then(res => {
                    this.userRequests = res.data;
                    console.log('user req', res.data);
                })
                .catch(err => {
                    this.$toast.error('Hata:' + err);
                    // Hata mesajını burada göster
                });
            // Kategorize edilmiş ürünleri ve kullanıcının taleplerini al
            // Bu axios istekleri örneğinize göre uyarlanmalıdır
            axios
                .get('/clock')
                .then(response => {
                    this.systemHours = response.data[0];
                    this.isWithinSystemHours = this.isCurrentTimeWithinSystemHours();
                })
                .catch(error => {
                    console.error('Sistem saatleri alınırken hata oluştu', error);
                });
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            // Düzleştirilmiş ürün listesi, kategoriler altında gruplanmadan
            flattenedProducts() {
                return this.categories.flatMap(category => category.products);
            },
            /*             totalAmountForCurrentUser() {
                // Sadece aktif kullanıcının taleplerini toplayarak toplam miktarı hesapla
                return this.userRequests.filter(request => request.userid === this.userId).reduce((total, request) => total + Number(request.amount || 0), 0);
            }, */
            totalAmountForCurrentUser() {
                return this.filteredCategories
                    .flatMap(category => category.products)
                    .reduce((total, product) => {
                        const request = this.userRequests.find(req => req.userid === this.user.id && req.productid === product.productid);
                        return total + (request ? parseFloat(request.amount || 0) : 0);
                    }, 0);
            },
            filteredCategories() {
                if (!this.searchQuery) {
                    return this.categories;
                }
                return this.categories
                    .map(category => {
                        const isCategoryMatched = category.categoryName.toLowerCase().includes(this.searchQuery.toLowerCase());
                        const filteredProducts = isCategoryMatched
                            ? category.products
                            : category.products.filter(product => product.productName.toLowerCase().includes(this.searchQuery.toLowerCase()));
                        return { ...category, products: filteredProducts };
                    })
                    .filter(category => category.products.length > 0 || category.categoryName.toLowerCase().includes(this.searchQuery.toLowerCase()));
            }
            // Diğer computed özellikler...
        }
    };
</script>

<style>
    .total-row {
        font-weight: bold;
    }

    thead th:nth-child(2){
        text-align: center;
    }
</style>
