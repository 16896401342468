<template>
    <div>
        <div class="table-responsive">
            <table ref="myTable" class="table table-striped table-hover custom-table">
                <thead>
                    <tr>
                        <th>Ürün Adı</th>
                        <th>Stok</th>
                        <th>Gelen</th>
                        <th>Dış Alınan</th>
                        <th v-for="user in nonAdminUsers" :key="user.userid">
                            {{ formatEmail(user.email) }}
                        </th>
                        <th>Verilen</th>
                        <th>Kalan</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="category in categories">
                        <tr class="category-header" :key="category.categoryid">
                            <td colspan="100%">
                                <h3>{{ category.categoryName }}</h3>
                            </td>
                        </tr>
                        <tr v-for="product in category.products" :key="product.productid">
                            <td>{{ product.productName }}</td>
                            <td>
                                <input type="number" class="form-control mobile-input" v-model.number="product.stock" @blur="updateProduct(product)" min="0" />
                            </td>
                            <td>
                                <input type="number" class="form-control mobile-input" v-model.number="product.incoming" @blur="updateProduct(product)" min="0" />
                            </td>
                            <td>
                                <input type="number" class="form-control mobile-input" v-model.number="product.fromout" @blur="updateProduct(product)" min="0" />
                            </td>
                            <td v-for="user in nonAdminUsers" :key="user.id">
                                <input
                                    type="number"
                                    class="form-control mobile-input"
                                    min="0"
                                    :value="getUserRequestAmount(user.id, product.productid)"
                                    @change="updateUserRequest(user.id, product.productid, $event.target.value)"
                                />
                            </td>
                            <td>
                                <input type="number" class="form-control mobile-input" v-model.number="product.toout" @blur="updateProduct(product)" min="0" />
                            </td>
                            <td>{{ calculateRemaining(product) }}</td>
                        </tr>
                    </template>
                </tbody>
                <tfoot>
                    <tr class="total-row">
                        <td><strong>Toplam</strong></td>
                        <td>{{ totalStock }}</td>
                        <td>{{ totalIncoming }}</td>
                        <td>{{ totalFromOut }}</td>
                        <td v-for="user in nonAdminUsers" :key="user.id">
                            <!-- Her kullanıcı için toplam talepleri göster -->
                            {{ totalRequestsByUser(user.id) }}
                        </td>
                        <td>{{ totalToOut }}</td>
                        <td>{{ totalRemaining }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="d-flex justify-content-around align-items-center">
            <button class="btn btn-primary" @click="exportToExcel">Excel'e Aktar</button>
            <button class="btn btn-primary" @click="exportToPdf">PDF'e Aktar</button>
            <button class="btn btn-danger" @click="resetTable">Tabloyu Sıfırla</button>
        </div>
    </div>
</template>

<!-- <input type="text" v-model="product.productName" @blur="updateProduct(product)" /> -->

<script>
    import axios from 'axios';
    import * as XLSX from 'xlsx';
    import jsPDF from 'jspdf';
    import html2canvas from 'html2canvas';

    export default {
        data() {
            return {
                categories: [],
                users: [],
                nonAdminUsers: [], // Rolü "admin" olmayan kullanıcılar için yeni bir veri öğesi
                userRequests: [] // user_request verileri
            };
        },
        mounted() {
            console.log('user rolü', this.$store.state.user.role);
            axios
                .get('/category/getCategoriesWithProducts')
                .then(res => {
                    this.categories = this.transformData(res.data);
                })
                .catch(err => {
                    console.error('Hata:', err);
                    // Burada kullanıcıya uygun bir hata mesajı göster
                });
            axios
                .get('/users')
                .then(res => {
                    console.log(res);
                    this.users = res.data;
                    this.nonAdminUsers = this.users.filter(user => user.role !== 'admin');
                    console.log(this.nonAdminUsers.length);
                })
                .catch(err => {
                    this.$toast.error('Hata:' + err);
                });
            axios
                .get('/userRequest')
                .then(res => {
                    this.userRequests = res.data;
                    console.log('user req', res.data);
                })
                .catch(err => {
                    this.$toast.error('Hata:' + err);
                    // Hata mesajını burada göster
                });
        },
        methods: {
            transformData(data) {
                const categoriesMap = new Map();
                data.forEach(item => {
                    if (!categoriesMap.has(item.categoryid)) {
                        categoriesMap.set(item.categoryid, {
                            categoryid: item.categoryid,
                            categoryName: item.categoryName,
                            products: []
                        });
                    }
                    categoriesMap.get(item.categoryid).products.push({
                        productid: item.productid,
                        productName: item.productName,
                        stock: item.stock,
                        incoming: item.incoming,
                        fromout: item.fromout,
                        toout: item.toout
                    });
                });
                return Array.from(categoriesMap.values());
            },
            formatEmail(email) {
                // E-posta adresinin "@" öncesini döndür
                return email.split('@')[0];
            },
            getUserRequestAmount(userid, productid) {
                console.log('user e', userid, productid);
                const request = this.userRequests.find(req => req.userid === userid && req.productid === productid);
                return request ? request.amount : 0;
            },

            updateProduct(product) {
                // Ürün bilgilerini güncelleme API isteği
                axios
                    .put(`/product/${product.productid}`, product)
                    .then(() => {
                        this.$toast.success('Ürün başarıyla güncellendi.');
                        // Gerekirse yerel verileri güncelle
                        this.refreshLocalProductData(product.productid);
                    })
                    .catch(() => {
                        this.$toast.error('Ürün güncellenemedi.');
                    });
            },

            // Kullanıcı taleplerini güncelleme
            /*             updateUserRequest(userid, productid, newAmount) {
                // Kullanıcı talebini güncelleme API isteği
                axios
                    .put(`/userRequest/${userid}/${productid}`, { amount: newAmount })
                    .then(() => {
                        this.$toast.success('Kullanıcı talebi başarıyla güncellendi.');
                        // Gerekirse yerel verileri güncelle
                        this.refreshLocalUserRequestData(userid, productid, newAmount);
                    })
                    .catch(() => {
                        this.$toast.error('Kullanıcı talebi güncellenemedi.');
                    });
            }, */
            updateUserRequest(userid, productid, newAmount) {
                // Önce bu kullanıcının bu ürün için mevcut bir talebi olup olmadığını kontrol et
                const existingRequest = this.userRequests.find(request => request.userid === userid && request.productid === productid);

                if (existingRequest) {
                    // Mevcut bir talep varsa, güncelle (PUT)
                    axios
                        .put(`/userRequest/${userid}/${productid}`, { amount: newAmount })
                        .then(() => {
                            this.$toast.success('Kullanıcı talebi başarıyla güncellendi.');
                            // Gerekirse yerel verileri güncelle
                            this.refreshLocalUserRequestData(userid, productid, newAmount);
                        })
                        .catch(() => {
                            this.$toast.error('Kullanıcı talebi güncellenemedi.');
                        });
                } else {
                    // Talep yoksa, yeni bir talep oluştur (POST)
                    axios
                        .post(`/userRequest`, { userid, productid, amount: newAmount })
                        .then(response => {
                            this.$toast.success('Yeni kullanıcı talebi başarıyla oluşturuldu.');
                            // Gerekirse yerel verileri güncelle
                            this.refreshLocalUserRequestData(userid, productid, newAmount);
                            this.userRequests.push(response.data);
                            // Reaktif güncellemeyi tetiklemek için userRequests dizisini güncelle
                            this.userRequests = [...this.userRequests];
                        })
                        .catch(() => {
                            this.$toast.error('Yeni kullanıcı talebi oluşturulamadı.');
                        });
                }
            },

            refreshLocalProductData(updatedProductId) {
                axios
                    .get(`/product/${updatedProductId}`)
                    .then(response => {
                        const updatedProduct = response.data;
                        this.categories.forEach(category => {
                            const index = category.products.findIndex(product => product.productid === updatedProductId);
                            if (index !== -1) {
                                this.$set(category.products, index, updatedProduct);
                            }
                        });
                    })
                    .catch(error => {
                        console.error('Yerel ürün verilerini güncellerken hata oluştu:', error);
                    });
            },

            refreshLocalUserRequestData(userid, productid, newAmount) {
                // Yerel userRequests dizisinde ilgili talebi bul ve güncelle
                const requestIndex = this.userRequests.findIndex(req => req.userid === userid && req.productid === productid);
                if (requestIndex !== -1) {
                    this.$set(this.userRequests, requestIndex, { userid, productid, amount: newAmount });
                }
            },

            calculateRemaining(product) {
                let totalRequested = 0;
                this.nonAdminUsers.forEach(user => {
                    const request = this.userRequests.find(req => req.userid === user.id && req.productid === product.productid);
                    totalRequested += request ? parseFloat(request.amount) : 0;
                });
                return parseFloat(product.stock + product.incoming + product.fromout - totalRequested - product.toout);
            },
            /*             exportToExcel() {
                // Excel dosyası için başlık satırını oluştur
                const header = ['Kategori Adı', 'Ürün Adı', 'Stok', 'Gelen', 'Dış Alınan', ...this.nonAdminUsers.map(user => user.email.split('@')[0]), 'Verilen', 'Kalan'];

                // Kategoriler ve ürünler için veri satırlarını oluştur
                const data = this.categories.flatMap(category => {
                    const categoryRow = new Array(header.length).fill('');
                    categoryRow[0] = category.categoryName;

                    const productRows = category.products.map(product => {
                        const totalRequested = this.nonAdminUsers.reduce((total, user) => {
                            const request = this.userRequests.find(req => req.userid === user.id && req.productid === product.productid);
                            return parseFloat(total) + (request ? parseFloat(request.amount) : 0);
                        }, 0);

                        const row = [
                            '', // Kategori adı boş bırakılır
                            product.productName,
                            product.stock,
                            product.incoming,
                            product.fromout,
                            // Kullanıcı talepleri
                            ...this.nonAdminUsers.map(user => {
                                const request = this.userRequests.find(req => req.userid === user.id && req.productid === product.productid);
                                return request ? parseFloat(request.amount) : 0;
                            }),
                            // Verilen ve kalan hesaplamaları
                            product.toout, // Verilen (doldurulacak)
                            product.stock + product.incoming + product.fromout - product.toout - totalRequested // Kalan
                        ];
                        return row;
                    });

                    return [categoryRow, ...productRows];
                });

                // Header ve data satırlarını birleştir
                const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plusiyer');
                XLSX.writeFile(workbook, 'plusiyer.xlsx');
            }, */
            exportToExcel() {
                const header = ['Kategori Adı', 'Ürün Adı', 'Stok', 'Gelen', 'Dış Alınan', ...this.nonAdminUsers.map(user => user.email.split('@')[0] + ' Talebi'), 'Verilen', 'Kalan'];

                const data = this.categories.flatMap(category => {
                    const productRows = category.products.map(product => {
                        const totalUserRequests = this.nonAdminUsers.reduce((total, user) => {
                            const request = this.userRequests.find(req => req.userid === user.id && req.productid === product.productid);
                            return total + (request ? parseFloat(request.amount || 0) : 0);
                        }, 0);

                        const remaining = product.stock + product.incoming + product.fromout - product.toout - totalUserRequests;
                        return [
                            category.categoryName,
                            product.productName,
                            product.stock,
                            product.incoming,
                            product.fromout,
                            ...this.nonAdminUsers.map(user => {
                                const request = this.userRequests.find(req => req.userid === user.id && req.productid === product.productid);
                                return request ? parseFloat(request.amount || 0) : 0;
                            }),
                            product.toout,
                            remaining
                        ];
                    });

                    // Kategori başlığını her ürün grubu için bir kez ekleyin
                    if (productRows.length > 0) {
                        productRows[0][0] = category.categoryName;
                    }

                    return productRows;
                });

                // Her sütun için toplamları hesapla
                const totalsRow = new Array(header.length).fill(0);
                totalsRow[0] = 'Toplam'; // İlk hücreye 'Toplam' yaz
                data.forEach(row => {
                    row.forEach((cell, index) => {
                        if (!isNaN(cell) && typeof cell === 'number') {
                            totalsRow[index] = (totalsRow[index] || 0) + cell;
                        }
                    });
                });

                // Toplam satırını en alta ekle
                data.push(totalsRow);

                // Excel dosyasını oluştur ve kaydet
                const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plusiyer');
                XLSX.writeFile(workbook, 'plusiyer.xlsx');
            },
            exportToPdf() {
                html2canvas(this.$refs.myTable, { scale: 2 }).then(canvas => {
                    const imgWidth = 208; // PDF içindeki görüntü genişliği
                    const pageHeight = 295; // A4 sayfa yüksekliği
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    const pdf = new jsPDF('p', 'mm');
                    let position = 0;

                    // İlk sayfayı ekle
                    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;

                    // Gerekirse ek sayfalar ekle
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        pdf.addPage();
                        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }

                    pdf.save('rapor.pdf');
                });
            },

            resetTable() {
                const productUpdates = this.categories.flatMap(category =>
                    category.products.map(product => {
                        return this.resetProduct(product);
                    })
                );

                const userRequestUpdates = this.userRequests.map(request => {
                    return this.resetUserRequest(request);
                });

                Promise.all([...productUpdates, ...userRequestUpdates])
                    .then(() => {
                        // Veritabanında güncelleme başarılıysa, yerel verileri de sıfırla
                        this.categories.forEach(category => {
                            category.products.forEach(product => {
                                product.stock = 0;
                                product.incoming = 0;
                                product.fromout = 0;
                                product.toout = 0;
                            });
                        });

                        this.userRequests.forEach(request => {
                            request.amount = 0;
                        });

                        this.$toast.success('Tüm veriler başarıyla sıfırlandı.');
                    })
                    .catch(() => {
                        this.$toast.error('Veriler sıfırlanırken bir hata oluştu.');
                    });
            },

            // resetProduct ve resetUserRequest fonksiyonları...

            resetProduct(product) {
                // Ürün güncelleme API isteği
                return axios.put(`/product/${product.productid}`, {
                    productName: product.productName,
                    stock: 0,
                    incoming: 0,
                    fromout: 0,
                    toout: 0
                });
            },

            resetUserRequest(request) {
                // Kullanıcı talebi güncelleme API isteği
                return axios.put(`/userRequest/${request.userid}/${request.productid}`, {
                    amount: 0
                });
            }

            /*   resetTable() {
                    this.categories.forEach(category => {
                        category.products.forEach(product => {
                            // Ürün değerlerini sıfırla
                            product.stock = 0;
                            product.incoming = 0;
                            product.fromout = 0;
                            product.toout = 0;

                            // Değişiklikleri backend'e gönder
                            this.resetProduct(product);
                        });
                    });

                    this.userRequests.forEach(request => {
                        request.amount = 0;
                        this.resetUserRequest(request);
                    });


                },

                resetProduct(product) {
                    // API endpoint'inize ve request yapısına bağlı olarak değişiklik gösterebilir
                    axios
                        .put(`/product/${product.productid}`, {
                            productName: product.productName,
                            stock: product.stock,
                            incoming: product.incoming,
                            fromout: product.fromout,
                            toout: product.toout
                        })
                        .then(() => {
                            // Başarılı güncelleme işlemi sonrası
                            console.log('productid', product.productid);
                            this.$toast.success('Tablo Sıfırlama İşlemi Başarılı.', response);
                            // İsteğe bağlı olarak kullanıcıya başarı mesajı gösterilebilir
                        })
                        .catch(error => {
                            // Hata durumunda
                            this.$toast.error('Ürün Verileri Sıfırlanamadı.', error);
                            // Kullanıcıya hata mesajı gösterilebilir
                        });
                },

                resetUserRequest(request) {
                    // Kullanıcı talebi güncelleme API isteği
                    axios
                        .put(`/userRequest/${request.userid}/${request.productid}`, {
                            amount: 0
                        })
                        .then(() => {
                            this.$toast.success('Tablo Sıfırlama İşlemi Başarılı.', response);
                        })
                        .catch(error => {
                            this.$toast.error('Kullanıcı Verileri Sıfırlanamadı.', error);
                        });
                } */
        },
        computed: {
            totalStock() {
                return this.categories.flatMap(category => category.products).reduce((total, product) => total + Number(product.stock || 0), 0);
            },
            totalIncoming() {
                return this.categories.flatMap(category => category.products).reduce((total, product) => total + Number(product.incoming || 0), 0);
            },
            totalFromOut() {
                return this.categories.flatMap(category => category.products).reduce((total, product) => total + Number(product.fromout || 0), 0);
            },
            // diğer sütun toplamlarını hesaplayan computed properties

            totalRequestsByUser() {
                // Her kullanıcı için toplam talepleri hesapla
                return userId => {
                    return this.userRequests.filter(req => req.userid === userId).reduce((total, req) => total + Number(req.amount || 0), 0);
                };
            },
            totalToOut() {
                return this.categories.flatMap(category => category.products).reduce((total, product) => total + Number(product.toout || 0), 0);
            },
            totalRemaining() {
                return this.categories
                    .flatMap(category => category.products)
                    .reduce((total, product) => {
                        const totalUserRequestsForProduct = this.userRequests.filter(req => req.productid === product.productid).reduce((sum, req) => sum + Number(req.amount || 0), 0);

                        const remaining = product.stock + product.incoming + product.fromout - product.toout - totalUserRequestsForProduct;
                        return total + Number(remaining || 0);
                    }, 0);
            },
            columnCount() {
                return this.nonAdminUsers.length + 6; // Sabit sütunlarınızın sayısı
            }
        }
    };
</script>

<style scoped>
    th,
    td {
        min-width: 120px; /* Minimum genişlik değerini ihtiyaca göre ayarlayabilirsiniz */
    }

    /*     th {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        white-space: nowrap;
    } */

    @media (max-width: 768px) {
        .mobile-input {
            font-size: 10px; /* Daha büyük bir font boyutu */
            padding: 10px; /* Daha fazla padding */
        }
    }
</style>
